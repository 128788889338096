input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.inner-main{display: flex;min-height: 100vh;padding: 15px;justify-content: center;align-items: flex-start;padding-top: 200px;
    @media(max-width:575px){}
    .purple-btn{@include animation-anime(transleft, 1s, linear);}
}

/* Card Panel */
.card-panel{
    width: 100%;max-width: 380px;@include bd-radius(15px);
    background-color: $white;padding: 20px 10px 10px;margin: 0 auto;box-shadow: 0 4px 4px rgba(0,0,0,0.05);
    @include animation-anime(transdown, 1s, linear);
    h6{color: $textcolor;font-weight: 600;margin-bottom: 15px;padding-left: 10px}
    input.form-control{
        @include bd-radius(15px);height: 50px;background-color:$lightergrey;text-align: center;
        margin: 0;font-size: 16px;border-color: $border-color;
        &:focus{border-color: $color;}
        &::placeholder{color: $lightgrey;font-size: 16px;}
        &:-ms-input-placeholder {color: $lightgrey;font-size: 16px;}
        &::-ms-input-placeholder {color: $lightgrey;font-size: 16px;}
    }
    label{color: $textcolor;font-size: 14px;font-weight: 500;}
    .your-amount-pnl{
        position: relative;
        .amount-pnl{
            padding: 15px;background-color: $grey-bg;@include bd-radius(15px);margin-bottom: 5px;
            min-height: 120px;
            p{color: $textcolor;font-size: 15px;font-weight: 600;margin: 0;}
            h3{color: $textcolor;margin: 0;font-size: 36px;
                sub{color: $textcolor;font-size: 14px;}
            }
            .small-img-pnl{
                display: inline-block;
                text-align: right;
                img{max-width: 50px;}
                span{display: inline-block;width: 100%;margin-top: 5px;
                    font-size: 16px;font-weight:600;color:$textcolor;
                    img{max-width: 20px;}
                }
               }
            
            
        }
    }
      
    .billing-address-pnl{
        display: flex;justify-content: space-between;align-items: center;border: 1px solid $border-color;
        @include bd-radius(15px);padding: 12px 15px;background-color: $lightergrey;
        p{margin: 0;color: $lightgrey;}
    }
}
.payment-methode-pnl{
    padding: 15px;background-color: $grey-bg;@include bd-radius(15px);margin-bottom: 10px;
    display: flex;align-items: center;
    .img-pnl{min-width:50px;width: 50px;height: 50px;border-radius: 50%;background-color: $color;display: inline-flex;justify-content: center;align-items: center;}
    .txt-pnl{padding-left: 10px;
        h5{font-size: 16px;font-weight: 600;color: $black;margin: 0;}
        p{color: $textcolor;font-size: 12px;margin: 0;}
    }
    &:hover{
        background-color: $color;
        .txt-pnl{
            h5,p{color: $white;}
        }
    }
}
.payment-methode-pnl.active{
    background-color: $color;
    .txt-pnl{
        h5,p{color: $white;}
    }
}
/* Card Panel */

.card-detail-pnl{
    .card-panel label{color: $lightgrey;padding-left: 10px;}
}
.border-div{
    width: 100%;
    height: 1px;
    background-color: $lightgrey;
    max-width: 380px;
    margin: 0 auto 20px;
}
/* Drop Down */
.dropdown{ 
    button{background-color: $white;@include bd-radius(35px);border: none;font-size: 18px;font-weight: 600;color: $black;
        border: 1px solid #CFCFCF;
        &:hover,&:focus{border-color: $color !important;}
    img{max-height: 30px;max-width: 30px;margin-right: 5px;position: relative;top: -1px;}
        &:after{display: none;}
        svg{margin-left: 3px;position: relative;top: 2px;}
        &:hover{
            background-color: $color;
            color: $white;
        }
    }
    &:focus button,&.show button{background-color: $color;color: $white;}
    .dropdown-menu{
        padding: 0;left:unset;right: unset;border: none;@include bd-radius(10px);box-shadow: 0 0 4px 2px rgba(172,122,247,0.25);
        a{padding: 10px 15px;font-size: 16px;font-weight: 500;color: $textcolor;
            &:first-child{border-radius: 10px 10px 0 0;}
            &:last-child{border-radius: 0 0 10px 10px;}
            &:hover{background-color: $color;color: $white;}
            &.bnce-btn{border-radius: 10px;}
        }
    }
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, 
.show>.btn-primary.dropdown-toggle,.btn-primary.focus, .btn-primary:focus,.show>.btn-primary.dropdown-toggle,
.show>.btn-primary.dropdown-toggle:focus{
    background-color: $color;color: $white;box-shadow: none !important; 
}
/* Drop Down */

// Arrow Button
.arrow-btn-cntnr{position: relative;width: 100%;
    .arrow-btn{
        background-color: $grey-bg;border:5px solid $white;color: $black;position: absolute;
        @include bd-radius(12px);left: calc(50% - 23px);top: calc(50% - 23px);z-index: 5;
        &:hover{background-color: $color;color: $white;}
    }
}
// Arrow Button
.btn-cntnr{text-align: right;
    @media(max-width:767px){text-align: center;}
}

// Gift Card
.gift-card-cntnr{
    display: inline-block;width: 100%;margin-bottom: 30px;@include animation-anime(transdown, 1s, linear);
    .gift-card{
        display: inline-block;width: 100%;position: relative;box-shadow: 0 0px 10px 2px rgba(172,122,247,0.25);
        background-color: $white;height:220px;@include bd-radius(15px);margin-bottom:10px;border:2px solid $white;
        @include trans; 
        .bg-layer{background-size: 100% 100%;z-index: 1;}
        h6{position: absolute;right: 15px;bottom: 5px;color: $textcolor;font-size:20px;z-index: 3;
            text-transform: uppercase;
            b{margin-right: 5px;}
        }
        h5{position: absolute;left: 15px;bottom: 5px;color: $textcolor;font-size:20px;z-index: 3;
            text-transform: uppercase;
            b{margin-left: 5px;color: #FFFFFF;}
        }
        img{max-height: 100%;width: 100%;@include bd-radius(12px);opacity: 0.9;}
        &.active{box-shadow: 10px 10px 10px rgba(172,122,247,0.5);
            h6{color: $white;}   
        }
    }
    &:hover{
        .gift-card{box-shadow: 10px 10px 10px rgba(172,122,247,0.5);}
    }
}
// Gift Card

/* Modal */
.modal-content{
    @include bd-radius(20px);box-shadow: 10px 10px 10px  rgba(172,122,247,0.25); 
    border-color: $border-color;
    .modal-header{border: none;padding-bottom: 0;
        .modal-title{font-size: 18px;font-weight: 600;color: $textcolor;}
    }
    label{font-size: 12.8px;}
    input.form-control{height: auto;border: none;box-shadow: none;padding: 0;font-size: 20px;    background: transparent;
        &::placeholder{color: $textcolor;font-size: 20px;font-weight: 400;}
        &:-ms-input-placeholder {color: $textcolor;font-size: 20px;font-weight: 400;}
        &::-ms-input-placeholder {color: $textcolor;font-size: 20px;font-weight: 400;}
    }
    button{max-width: 100%;}
    .modal-body{padding:1rem 10px 10px;}
    .send-gift-pnl{margin-bottom: 10px;}
    p{color: $textcolor;}
    .btn-list{
        display: flex;width:100%;margin:0;
        li{width: 50%;list-style: none;
            &:first-child{padding-right: 5px;}
            &:last-child{padding-left: 5px;}
            button{width: 100%;height: 55px;border-radius: 10px;border:1px solid $color;}
        }
    }
}
.send-gift-pnl{
    border: 1px solid;border-color: $border-color;@include bd-radius(15px);
    background-color: $grey-bg;padding: 15px;margin-bottom: 20px;
}
/* Modal */
@media(min-width:576px){
    .p-r10{padding-right: 5px;}
    .p-l10{padding-left: 5px;}
}
.inputfiled{
    color: $textcolor;margin: 0;font-size: 36px; background: transparent;border: none;width: 200px; font-weight: 500;
   outline: none;
   
 
}
.inputfiledamount{
    color: $textcolor;margin: 0;font-size: 36px; background: transparent;border: none;width: 200px; font-weight: 500;
   outline: none;
   pointer-events: none;
   
 
}

.walletaddress{
    background: transparent;
    border: none;
    outline: none;
width: 100%;
margin-top: 15px;
}

.pagination-container {
    text-align: right;
    padding: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    .pagination {
      display: inline-flex;
      li,
      .page-item {
        a {
          border: none;
          color: $black;
          font-size: 14px;
          &.active,
          &:active,
          &:hover {
            color: $white;
            background-color: $color;
            span {
              color: $white;
            }
          }
        }
        &.active {
          a,
          .page-link {
            background-color: transparent !important;
            border: none;
            color: $color;
          }
        }
      }
    }
  }
  
  .pagination_2 .pagination_2 li a,
  .pagination_2 .pagination_2 .page-item a {
    background-color: rgba(119, 131, 143, 0.1);
    color: $white;
  }
  .pagination-container .pagination li a.active,
  .pagination-container .pagination li a:active,
  .button .pagination-container .pagination li a:hover,
  .pagination-container .pagination .page-item a.active,
  .pagination-container .pagination .page-item a:active,
  .pagination-container .pagination .page-item a:hover {
    background-color: $color;
    color: $white;
  }


  .laoder {
    color: #AC7AF7;
    display: flex;
    justify-content: center;
    
  }

  .ld {
    // width: 100px !important;
    // height: 100px !important;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }


  .send-gift-pnl .form-control:focus {
    border-color: #ced4da; /* Change this to the desired border color */
    box-shadow: none;
}
.send-gift-pnl .purple-btn{
    opacity: none !important;
} 


.Model{
    justify-content: center !important;
}
button {
    background: var(--wui-color-accent-100);
    border: 1px solid var(--wui-gray-glass-010);
    border-radius: var(--wui-border-radius-m);
    gap: var(--wui-spacing-xs);
}
.modal-header {
    // justify-content: center !important;
}
#modelCard{
    justify-content: center !important;
}

#CardBox{
    box-shadow: none !important;
    max-width: 487px !important;
}

.close-btn{
    padding: 0;font-size: 26px;border: none;position: absolute;right: 20px;top: 10px;
    background-color: transparent !important;color: black;
    &:hover {color:#AC7AF7 ;}
    &:focus {
        color: black !important; 
    }
}

.cardinput{
    background: transparent;
    border: none;
    font-size: 20px;
    &:focus-visible{
        outline: none;
    }
}

.cardinputCCv{
    // background: transparent;
    background-color: #F8F8F8;
    border-radius: 15px;
    font-size: 20px;
text-align: center;
min-width: 120px;
    border: none;
    &:focus-visible{
        outline: none;
    }
}

.cardinputExp{
    // background: transparent;
    background-color: #F8F8F8;
    border-radius: 15px;
    font-size: 20px;
text-align: center;
min-width: 120px;
    border: none;
    &:focus-visible{
        outline: none;
    }
}

.cardimg{
    // position: absolute;
    width: 42px;
    height: 25px;
    top: 6px;
    left: 15px;
    background-color: rgba(0,0,0,.05);
    border-radius: 4px;
    background-size: 42px;
    z-index: 100;

}

.modal .card-panel{
    animation: unset !important;
  }

//   .css-b73i7x svg {
//     left: 0px !important;
//   }
.css-b73i7x img{
    margin-right: 15px !important;
}

  .coinimg{
    width: 25px;
  }

  .balacebtn{
    font-weight: 600;
    color: #AC7AF7;
    background-color: transparent !important;
  }
  .balancebtn{
    color: #727272;
    font-weight: 500;
    background-color: transparent !important;
  }
  .dropdown .dropdown-menu .balacebtn:hover {
    background-color: transparent;
    color: #ffffff;
}
.dropdown .dropdown-menu .balancebtn:hover {
    background-color: #AC7AF7;
    color: #ffffff;
}
    @media(max-width:575px){
        .cardinputExp{
            width: 100%;
            min-height: 50px;
        }.cardinputCCv{
            width: 100%;
            min-height: 50px;
        }
        
    }

    @media(max-width:575px){
        .Toastify__toast-container{max-width: 300px !important;left: calc(50% - 150px) !important;margin-bottom: 35px !important;}
    }
    // .Toastify__close-button{
    //     color: #6B7280 !important;opacity: 1 !important;position: relative !important;top: 22px !important;right: 18px !important;
    //     svg{width: 20px;height: 20px;}
    // }
    @media  (max-width: 480px){
    .Toastify__toast {
        margin-bottom: 10px !important;
        border-radius: 0;
    }
}
    @media(max-width:575px){
        .card-panel {
            max-width: 403px;
        }
    }

    .showbtn{
        display: none;
    }

    @media(max-width:767px){
        .removebtn {
            display: none;
        }
    }
    @media(max-width:767px){
        .showbtn {
            display: inline-block !important;
        }
    }
    @media(max-width:370px){
    .navbar-brand{
        width: 32px;
    }.profile{
        min-width: 20px !important;
    }
}
@media(max-width:575px){
    .uniswap {
        width:  380px !important;
    }
}
@media(max-width:414px){
    .uniswap {
        width:  365px !important;
    }
}

@media(max-width:390px){
    .uniswap {
        width:  338px !important;
    }
}
@media(max-width:375px){
    .uniswap {
        width:  325px !important;
        height: 388px !important;
    }
}

.comming-soon-pnl{
    width: 100%;height: 100vh;
    display: flex;justify-content: center;align-items: center;
    h1{font-weight: 700;text-transform: capitalize;}
}