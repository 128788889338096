.bg-gradient{@include gradient;}
.log-main{
    display: flex;height: 100vh;min-height: 800px;padding: 15px;
    @media(max-width:1200px){min-height: 700px;}
    @media(max-width:767px){display: inline-block;height:auto;min-height:unset;}
    h4{font-weight: 400;}
    .intro{
        display: flex;justify-content: center;align-items: flex-start;width: 50%;height: 100%;
        @include bd-radius(15px);position: relative;z-index: 2;padding: 100px 30px 15px;
        @media(max-width:767px){display: inline-block;width:100%;padding:20px 30px 300px;text-align:center;}
        @media(max-width:575px){padding: 20px 15px 200px;}
        .bg-layer{
            z-index: -1;background-image: url(../Assets/image/bg.png);
            background-repeat: no-repeat;background-size: 85%;background-position: center bottom;
            @media(max-width:1200px){background-size: 75%;}
            @media(max-width:991px){background-size: 80%;}

            @media(max-width:767px){background-size: 400px;}
            @media(max-width:575px){background-size: 300px;}
        }
        .intro-inner{max-width: 550px;width: 100%;margin: 0 auto;@include animation-anime(transup, 1s, linear);}
        h1{margin: 15px 0;font-weight: 700;}
        h4,h1{color: $white;}
    }
    .welcome-pnl{display: flex;justify-content: center;align-items: center;width: 50%;height: 100%;@include bd-radius(15px);padding: 0 30px;   
        @media(max-width:767px){display: inline-block;width:100%;padding:50px 30px 20px;}
        @media(max-width:575px){padding: 50px 0px 20px;}
        .welcome-inner{max-width: 450px;width: 100%;text-align: center;margin: 0 auto;@include animation-anime(transdown, 1s, linear);} 
        h2{margin: 0;color: $black;}
        h4{color: $textcolor;margin-bottom: 20px;font-weight: 500;}
        a{font-size: 18px;color: $black;font-weight: 600;
            &:hover{color: $color;}
        }
    }
    a.Submit-btn,
    .Submit-btn{
        color: $white;font-size: 22px;font-weight: 500;width: 100%;text-align: center;border: none;height: unset;
        padding: 19px 15px;border-radius: 25px 0 25px 25px;@include boxshadow;
        background: linear-gradient(to right,  rgba(172,122,247,1) 0%,rgba(148,88,239,1) 100%);

        &:hover{color: $white;box-shadow: 0 10px 10px rgba(172,122,247,0.5);}
    }
}