*{margin: 0;padding: 0;image-rendering: -o-crisp-edges;image-rendering: -webkit-optimize-contrast;-ms-interpolation-mode: nearest-neighbor}
body,html,main{-webkit-font-smoothing: antialiased}
a,a:focus,button,button:focus{outline: 0 !important;box-shadow: none;}
a{display: inline-block;text-decoration: none !important;}
.bg-layer{opacity: 1;background-size: cover;background-position: center center;}
.bg-layer{position: absolute;top: 0;left: 0;}
.bg-layer,iframe,video,source{height: 100%;width: 100%}
img,picture,svg{max-width: 100%;}
.full-div{display: inline-block;width: 100%;}
.half-div{display: inline-block;width: 50%;float: left;}
.txt-right,.text-right{text-align: right;}
.txt-center,.txt-centr,.text-center{text-align: center;}
.txt-left,.text-left{text-align: left;}
.flex-div{display: flex;justify-content: space-between;width:100%;align-items: center;}
.flex-div-sm{display: flex;justify-content: space-between;width:100%;
  @media(max-width:767px){display: inline-block;text-align:center;}
}
.flex-div-xs{display: flex;justify-content: space-between;width:100%;
  @media(max-width:575px){display: inline-block;text-align:center;}
}
.flex-divtext{display: flex;justify-content: space-between;width:100%; height: 50px;}
.flex-divtext-sm{display: flex;justify-content: space-between;width:100%;
  @media(max-width:767px){display: inline-block;text-align:center;}
}
.flex-divtext-xs{display: flex;justify-content: space-between;width:100%;
  @media(max-width:575px){display: inline-block;text-align:center;}
}
body,html{min-height: 100%;height: auto;}
img{max-width: 100%;}
div,img,h1,h2,h3,h4,h5,h6,p,a,li,span{@include trans();}
ul{padding-left: 0;}
.pd-r-0{padding-right: 0;}
.pd-l-0{padding-left: 0;}
body{overflow-x: hidden !important;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
@media(min-width:1300px){.container{max-width: 1300px;}}
/**** divider ***/
.spacer-10{width: 100%;height: 10px;display: block;clear: both;}
.spacer-20{width: 100%;height: 20px;display: block;clear: both;}
.spacer-30{width: 100%;height: 30px;display: block;clear: both;}
.spacer-40{width: 100%;height: 40px;display: block;clear: both;}
.spacer-50{width: 100%;height: 50px;display: block;clear: both;}
.spacer-60{width: 100%;height: 60px;display: block;clear: both;}
hr{display: block;clear: both;border-top: solid 1px #ddd;margin: 0px 0 10px;}
::placeholder{color: $lightgrey;}
:-ms-input-placeholder {color: $lightgrey;}
::-ms-input-placeholder {color: $lightgrey;}
input[type="checkbox"]{accent-color: $color;}
body{font-family: 'Raleway', sans-serif;font-weight: 400;font-size: 16px;background-color: #FAFAFA;}