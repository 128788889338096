/*color*/
$white: #ffffff;
$black: #131313;
$color: #AC7AF7;
$grey:#D5E1EC;
$textcolor:#727272;
$lightgrey:#ADADAD;
$lightergrey:#F8F8F8;
$grey-bg:#F1F1F1;
$grey-bgg:#F2F2F2;
$border-color:#E8E8E8;