@media(min-width:992px){
    .navbar-collapse{justify-content: space-between;}
}
.navbar{
    background-color: $white;@include bd-radius(15px);left: 15px;top: 15px;right: 15px;
    padding: 5px 10px;
    position: absolute;z-index: 999;box-shadow: 0 0 30px 10px rgba(172,122,247,0.15);
    @include animation-anime(Navanime, 1s, linear);
    @media(max-width:1200px){padding-left:0;padding-right:0;}
    @media(max-width:991px){padding-left:15px;padding-right:15px;}
    @media(max-width:767px){box-shadow: none;}
    .user-pf-pnl{text-align: right;margin: 0 15px;
        @media(max-width:1200px){margin:0px 10px;}
        @media(max-width:991px){margin:0px 15px;}
        @media(max-width:575px){margin: 8px 10px;}
        h6{margin: 0;color: $black;font-size: 20px;font-weight: 600;
            @media(max-width:1200px){font-size: 16px;}
            @media(max-width:991px){font-size: 18px;}
        }
        p{margin: 0;color:$lightgrey;
            @media(max-width:1200px){font-size: 14px;}
            @media(max-width:991px){font-size: 16px;}
        }
        .img-pnl{margin-left: 10px;@include bd-radius(10px);width: 50px;margin-right: 14px;
            @media(max-width:991px){width: 55px;}
            img{@include bd-radius(10px);}
        }
    }
    .navbar-nav .nav-link{font-size: 18px;font-weight: 500;color: $textcolor;margin-left: 30px;
        &.active,&:hover{color: $color;}
        @media(max-width:1400px){margin-left: 5px;}
        @media(max-width:1200px){font-size: 16px;}
        @media(max-width:991px){font-size: 18px;margin-left:3px;}
    }
    .nav-item.dropdown .nav-link{
        margin: 0;padding: 0;
        &::after{display: none;}
    }
    .dropdown-menu{
        padding: 0;left:25px !important;right: 15px;border: none;@include bd-radius(10px);
        box-shadow: 0 0 4px 2px rgba(172,122,247,0.25);
        a{padding: 10px 15px;font-size: 16px;font-weight: 500;color: $textcolor;
            &:first-child{border-radius: 10px 10px 0 0;}
            &:last-child{border-radius: 0 0 10px 10px;}
            &:hover{background-color: $color;color: $white;
                span{color: $white;}
            }
        }
    }
    button{background-color: transparent;border-color: $color;@include bd-radius(10px);font-weight: 600;
        font-size: 22px;color: $white;text-align: center;padding: 10px 15px;color: $color;
        @media(max-width:1200px){font-size: 16px;padding:10px 8px;}
        @media(max-width:991px){font-size: 16px;padding: 8px 15px;}
        @media(max-width:575px){padding: 8px 10px;font-size:14px;}

        &:not(.navbar-toggler){
            
        // @media(max-width:767px){display: none;}
        }

        span{color: $lightgrey;}
        &.connect-btn{
            padding: 12px 30px;@include bd-radius(10px);
            font-weight: 500;font-size: 18px;
            border: none;background-color: $white;
            box-shadow: 0 0 4px 2px rgba(172,122,247,0.25);
            @media(max-width:1200px){font-size: 16px;padding:10px 12px;}
            @media(max-width:991px){font-size: 16px;padding: 8px 15px;}
            @media(max-width:575px){padding: 8px 10px; margin-left: 0;}
        }
        &:hover{background-color: $color;color: $white;border-color: $color;
            span{color: $white;}
        }
    }
    #user-pr-pnl{display: none;align-items: center;
        button span{color: $textcolor;font-weight: 500;}
        @media(max-width:991px){display: inline-flex;margin-right:20px;
}
        @media(max-width:575px){margin-right: 5px;}
    }
    .navbar-collapse{
        #user-pr-pnl{display: flex;
            @media(max-width:991px){display: none;}
        }
    }
    .navbar-toggler{
        border:none;padding: 0;width: 30px;height: 30px;
        justify-content: center;align-items: center;position: relative;padding:0;margin:0;top:0;
        span{display: inline-block;width: 100%;height: 2px; background-image: none;background-color: $color;@include trans();opacity: 0;}
        &:before{position: absolute;content: "";left: 0;right: 0;top: 6px;height: 2px;background-color: $color;@include trans();
            transform: rotate(45deg);top: 15px;
        }
        &:after{position: absolute;content: "";left: 0;right: 0;bottom: 6px;height: 2px;background-color: $color;@include trans();
            transform: rotate(-45deg);bottom: 13px;
        }
        &:hover{background-color: transparent;
          &:before,&:after,
          span{background-color: $color;}
        }
        &.collapsed{
            span{opacity: 1;}
            &:after{transform: rotate(0deg);bottom: 6px;}
            &:before{transform: rotate(0deg);top: 8px;}
        }
    }
}
.profile{
    min-width: 50px;
    min-height: 40px;
}

.navbar .user-pf-pnl .img-pnl img {
    border-radius: 50% !important;
    margin-right: 14px !important;
}
.navbar .user-pf-pnl .txt-pnl{
    text-align: left;

}
@media(max-width:991px){
    button.blnce-btn{display: none !important;}
}
.cross{
    margin: 0;
    width: 30px;
    font-weight: 500 !important;
    position: relative;
    top:11px
}
.Bars{
    width: 30px;
    font-weight: 500 !important;
    position: relative;
    top:11px
}
.collaspbtn{
display: none;
}

@media(max-width:991px){
    .collaspbtn{display: inline-flex !important; color: #AC7AF7;}
}
