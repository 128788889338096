// Purple Button
.purple-btn{
    width: 100%;max-width: 450px;height: 55px;line-height: 55px;background-color: $color;
    font-size: 18px;font-weight: 600;text-align: center;@include bd-radius(15px);border: none;padding: 0 15px;opacity: 1;
    @include trans;color: $white;
    &:hover{background-color: $color;color: $white;opacity: 1; box-shadow: 0 10px 10px 2px rgba(172,122,247,0.25);}
}
// Purple Button

// Grey Button
.grey-btn{
    @include bd-radius(15px);background-color: $lightergrey;text-align: center;margin: 0;height: 65px;
    border-color: transparent;@include bd-radius(10px);color: $black;font-weight: 500;font-size: 20px;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.05);width: 100%;padding: 0 15px;color: $lightgrey;line-height:65px;
    &:hover{
        background-color: $color;color: $white;
        border-color: $color;box-shadow: 0 10px 10px 2px rgba(172,122,247,0.25);
    }
}
// Grey Button

// Send Button
.send-btn{
    font-size: 20px;color: $color;min-width: 140px;text-align: center;
    padding: 5px 15px;@include bd-radius(10px);font-size: 20px;font-weight: 500;
    box-shadow: 0 0px 4px 2px rgba(172,122,247,0.25);
    background-color: $white;border:none;
    &:hover{
        background-color: $color;color: $white;
        box-shadow: 0 0px 10px 2px rgba(172,122,247,0.25);
    }
}
// Send Button
.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #AC7AF7 !important ;
    border-color: transparent !important;
}
.copybtn{
    cursor: pointer;
    margin-left: 5px;
}

.copytext{
    color: #AC7AF7;
}
.textstyle{
    margin-left: 3px;
    font-size: 12px;
}